<template>
<div>
  <div class="top-con topbg">
    <div class="wcon">
      <div class="big-t">多品种筛选及分组管理</div>
      <div class="litw">
        <div class="bluel"></div>
        <div class="lw">
          <p>将普通投资者训练提升到量化交易者</p>
          <p>资管公司多人操作提升为单机多账户操作</p>
        </div>
      </div>
    </div>
  </div>
  <div class="compu">
    <img :src="require('@/assets/images/shaix1.png')" alt="" />
  </div>
  <div class="btcon">
    <div class="wcon">
      <div class="btn"><a href="">了解我们的产品</a></div>
      <div class="lw">
        LEARN ABOUT OUR PRODUCTS
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {

}
</script>

<style scoped lang="less">
.top-con {
  width: 100%;
  height: 616px;
  padding-top: 200px;
  min-width: 1000px;
  background-image: url("../../assets/images/shaix2.jpg");
  background-repeat: no-repeat;
  background-position: center bottom;
  .wcon {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    padding: 30px;
    box-sizing: border-box;
    .big-t {
      font-size: 22px;
      height: 147px;
      display: flex;
      align-items: center;
    }
    .litw {
      width: 100%;
      text-align: left;
      padding-top: 25px;
      .bluel {
        width: 70px;
        height: 10px;
        background: #5a9eff;
        border-radius: 5px;
      }
      .lw {
        padding: 20px 0;
        line-height: 35px;
        color: #333333;
      }
    }
  }
}
.compu {
  width: 100%;
  min-width: 1000px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 157px 0;
  img {
    max-width: 100%;
  }
}
.btcon {
  width: 100%;
  height: 630px;
  min-width: 1000px;
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size:100%;
  background-image: url("../../assets/images/shaix3.jpg");
  .wcon {
    width: 100%;
    max-width: 1200px;
    height: 100%;
    display: flex;
    margin: 0 auto;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    .btn{
      padding: 5px 20px;
      background: #0b22c8;
      border-radius: 5px;
      a{
        text-decoration: none;
        color:#ffffff;
        font-size: 20px;
      }
    }
    .lw{
      padding-top: 20px;
      font-size: 10px;
      color: #ffffff;
      letter-spacing: 3px;
    }
  }
}
</style>