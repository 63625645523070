import { render, staticRenderFns } from "./Sizer.vue?vue&type=template&id=64e9a645&scoped=true"
import script from "./Sizer.vue?vue&type=script&lang=js"
export * from "./Sizer.vue?vue&type=script&lang=js"
import style0 from "./Sizer.vue?vue&type=style&index=0&id=64e9a645&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64e9a645",
  null
  
)

export default component.exports